//If tabbed into the a tags the class mobile-open will be added
$('.share > nav > a').on('focus', function() {
  $('.share').addClass('mobile-open');
});
// when tabbed out of the mobile-open will remove itself
$('.share').focusout(function() {
  $('.share').removeClass('mobile-open');
});

//get the window width
var windowsize = $(window).width();

//Detexts window size changes and runs the function
$(window).resize(function() {
  //get the new window size
  windowsize = $(window).width();
  if (windowsize < 1024) {
    //if the window size is less than 1024px then add the class 
    $('.share').addClass('mobile-open');
  }
  else {
    //if it is more than 1024px remove the class
    $('.share').removeClass('mobile-open');
  }
});