jQuery(document).ready(function($){

  if ($(".footer-links__column").length === 2) {
    if ($(window).width() > 1024) {
      $('.footer-links__column:first-of-type').css('margin-right','20%');
      $('.footer-links__wrapper').css({'-webkit-justify-content': 'normal', 'justify-content': 'normal'});
      $('.footer-links__item').css({'float': 'left', 'margin-right': '3rem'});
    };
  }
  

    $('.sponsor-logos').slick({
      slidesToShow: 6,
      slidesToScroll: 1,
      variableWidth: false,
      variableHeight: false,
      autoplay: true,
      autoplaySpeed: 3500,
      arrows: false,
      infinite: true,
        // speed: 10000,
        // autoplay: true,
        // autoplaySpeed: 0,
        // cssEase: 'linear',
        // slidesToShow: 1,
        // slidesToScroll: 1,
        // variableWidth: true,
      dots: false,
        // pauseOnHover: false,
        responsive: [{
        breakpoint: 768,
        settings: {
          slidesToShow: 3
        }
      }, {
        breakpoint: 520,
        settings: {
          slidesToShow: 2
        }
      }]
    });

  // browser window scroll (in pixels) after which the "back to top" link is shown
	var offset = 300,
		//browser window scroll (in pixels) after which the "back to top" link opacity is reduced
		offset_opacity = 1200,
		//duration of the top scrolling animation (in ms)
		scroll_top_duration = 700,
		//grab the "back to top" link
		$back_to_top = $('.cd-top');

	//hide or show the "back to top" link
	$(window).scroll(function(){
		( $(this).scrollTop() > offset ) ? $back_to_top.addClass('cd-is-visible') : $back_to_top.removeClass('cd-is-visible cd-fade-out');
		// if( $(this).scrollTop() > offset_opacity ) { 
		// 	$back_to_top.addClass('cd-fade-out');
		// }
	});

  $('.sponsor-logos .sponsors-logos__image').each(function () {
    var $slide = $(this).parent();    
    if ($slide.attr('aria-describedby') != undefined && $('#' + $slide.attr('aria-describedby')).length == 0) { // ignore extra/cloned slides
        $(this).attr('id', $slide.attr('aria-describedby'));
    }
  });

	//smooth scroll to top
	$back_to_top.on('click', function(event){
		event.preventDefault();
		$('body,html').animate({
			scrollTop: 0 ,
		 	}, scroll_top_duration
		);
	});
});