if ($("form > .has-error").length > 0) {
  $("html, body").animate({ scrollTop: $(".has-error").offset().top});
}
if ($(".form__group > label:contains('mail')").length > 0) {
$('.form__group > input.email').blur(function() {
  var testEmail = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
  if (testEmail.test(this.value)) {
    $(this).parent('.form__group').removeClass('has-error');
    $(this).next('.form-control__feedback').remove();
  }
  else {
    $(this).parent('.form__group').addClass('has-error');
    if($(this).next('.form-control__feedback').length === 0) {
    $(this).after('<div class="form-control__feedback">This field is required. Please enter a value.</div>');
    }
  }
});
}

if ($('#mapid').length > 0) {
  var mymap = L.map('mapid').setView([55.9533, -3.1883], 13);
  L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}', {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 12,
      id: 'mapbox.streets',
      accessToken: 'pk.eyJ1IjoiY3JvY2tzIiwiYSI6ImNqazl0dzVhOTAwMHUzcm1tZGI3ZjhvaTAifQ.okYDOll94F1z9A9GF7Ybrw'
  }).addTo(mymap);
}

// Hide all dynamic form groups by default
$('#dynamic-inputs > .form__group').hide();
// Listen for when the Section and Type inputs change
$('[data-section], [data-type]').change(function(){
    // And if just the Event Type is checked
    if($('[data-section]:checked').val() == 1){
        // Disable and hide the External link type input
        $('[data-type][value="0"]').prop('disabled', true);
        $('[data-type][value="0"]').closest('li').hide();
        // Check the Page type input
        $('[data-type][value="1"]').prop('checked', true);
        // Show Event form groups
        $('[data-event]').closest('.form__group').show();
    }
    // Or if both a Section and Type input are checked
    else if($('[data-section]:checked').val() && $('[data-type]:checked').val()){
        var show = '';
        // Hide all dynamic form groups for now
        $('#dynamic-inputs > .form__group').hide();
        // Second Section → Events
        if($('[data-section]:checked').val() == 1){
            show = 'data-event';
            // Disable and hide the External link type input
            $('[data-type][value="0"]').prop('disabled', true);
            $('[data-type][value="0"]').closest('li').hide();
            // Check the Page type input
            $('[data-type][value="1"]').prop('checked', true);
        }
        // First Type → External Link
        else if($('[data-type]:checked').val() == 0) {
            show = 'data-link';
            // Enable and show the External link type input
            $('[data-type][value="0"]').prop('disabled', false);
            $('[data-type][value="0"]').closest('li').show();
        }
        // Second Type → Page
        else {
            // Enable and show the External link type input
            $('[data-type][value="0"]').prop('disabled', false);
            $('[data-type][value="0"]').closest('li').show();
            // Second Section → Events
            if ($('[data-section]:checked').val() == 1){
                show = 'data-event';
            }
            // Other Sections → News, Learn, Policy
            else {
                show = 'data-page';
            }
        }
        // Show matching form groups
        $('['+ show +']').closest('.form__group').show();
    }
});
