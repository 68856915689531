jQuery(function () {
  
    if (document.cookie.match('ck_pref=0')) {
        optOutCookiePolicy();
        toggleCookieBanner('hide');
    }

    // function wich show or hide cookie banner
    function toggleCookieBanner(action) {
      //check if baner exists
      if (!jQuery('#cookie-notice').length) {
        return
      }

      if (action === 'show') {
        jQuery('#cookie-notice').removeClass('cookie-policy--hidden').attr('aria-hidden', 'false');
      } else if (action === 'hide') {
        jQuery('#cookie-notice').addClass('cookie-policy--hidden').attr('aria-hidden', 'true');
      }
    }
    
    // Determine if the cookie policy needs to be shown or not
    // console.log(document.cookie.valueOf('ck_pref').includes("ck_pref=0"));
    document.cookie.indexOf('ck_pref') < 0 ? toggleCookieBanner('show') : toggleCookieBanner('hide');

    // Accept via bottom fixed popup OK button
    jQuery('#cookie-notice .acceptcookie').on('click', function (e) {
      e.preventDefault();
      // hideCookiePolicyPopup = hide the fixed cookie banner popup
      toggleCookieBanner('hide');
      setCookiePolicy('1');
      // If the cookie switch is OFF 
      if (jQuery('#cookie-switch').length > 0) {
        // If the cookies are ON the turn the switch ON
        if (document.cookie.match('ck_pref=1')) {
          jQuery('#cookie-switch').prop("checked", true);
        } else {
          // Otherwise double check the switch it off
          jQuery('#cookie-switch').prop("checked", false);
        }
      }
    });
  
    // Decline via bottom fixed popup OK button
    jQuery('#cookie-notice .declinecookie').on('click', function (e) {
      // If there isn't an option ot readmore then don't act as a button
      if (!jQuery(this).hasClass("readmore")) {
        e.preventDefault();
      }
      toggleCookieBanner('hide');
      // optOutCookiePolicy = set the ck_pref to 0 thus removing the defined cookies
      optOutCookiePolicy();
      // If the cookie switch is on the page
      if (jQuery('#cookie-switch').length) {
        if (document.cookie.match('ck_pref=1')) {
          // If the ck_pref is set to 1 (aka cookies are running)
          // Then show the cookie switch as ON
          jQuery('#cookie-switch').prop("checked", true);
        } else {
          // If cookies are not running show the cookie switch as OFF
          jQuery('#cookie-switch').prop("checked", false);
        }
      }
    });
  
    // Process the users action when toggling the cookie switch
    if (jQuery('#cookie-switch').length) {
      if (document.cookie.match('ck_pref=1')) {
        jQuery('#cookie-switch').prop("checked", true);
      } else {
        jQuery('#cookie-switch').prop("checked", false);
      }
  
      // If the cookie switch is toggled then...
      jQuery('#cookie-switch').on('change', function () {
        toggleCookieBanner('hide');
      
        // If switch is turned to ON
        if (jQuery('#cookie-switch').is(':checked')) {
          // setCookiePolicy = Update ck_pref. In this case to 1
          setCookiePolicy('1');
        } else {
          optOutCookiePolicy();
        }
      });
    }
    
    // Function which defines the variables to set the ck_pref
    function setCookiePolicy(preference) {
        // Get cookie expiry date (current date + 1 year)
        var date = new Date(Date.now());
        var year = date.getFullYear();
        year++;
        date.setFullYear(year);
        date = date.toUTCString();
        // Set cookie
        document.cookie = 'ck_pref=' + preference + '; path=/; expires=' + date;

        if (preference === 1) {
            runTagManager();
        }
    }
    
    // Function which sets the ck_pref and removes defined cookies
    function optOutCookiePolicy() {
      // Update cookie preference
      setCookiePolicy('0');
      // Delete non-required cookies e.g. GA
      var deleteCookieArray = [
        ['461630', '.squiz.cloud', '/'],
        ['nmstat', '.squiz.cloud', '/'],
        ['_ga', '.partnersinplanning.scot', '/'],
        ['_ga', '	.squiz.cloud', '/'],
        ['_hp2_props.4213461630', '	.squiz.cloud', '/'],
        ['_gat_gtag_UA_124655081_1', '.partnersinplanning.scot', '/'],
        ['_gid', '.partnersinplanning.scot', '/'],
      ];
      document.cookie = '_ga=; Domain=.partnersinplanning.scot Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      // Run through the array "deleteCookieArray" and remove/set new expirty date of 1970 which removes
      for (var i = 0; i < deleteCookieArray.length; i++) {
        document.cookie = deleteCookieArray[i][0] + '=; Domain=' + deleteCookieArray[i][1] + '; Path=' + deleteCookieArray[i][2] + '; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      }
    }
  
  });
