var searchEl,
    i,
    j,
    selElmnt,
    newSelectedEl,
    newItemEl,
    optionMenu;
/*look for any elements with the class "items-cards__select-group":*/
searchEl = document.getElementsByClassName('items-cards__select-group');
for (i = 0; i < searchEl.length; i++) {
	selElmnt = document.getElementById('items-cards__option');
	/*for each element, create a new DIV that will act as the selected item:*/
	newSelectedEl = document.createElement('DIV');
	newSelectedEl.setAttribute('class', 'select-selected');
	newSelectedEl.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
	searchEl[i].appendChild(newSelectedEl);
	/*for each element, create a new DIV that will contain the option list:*/
	newItemEl = document.createElement('DIV');
	newItemEl.setAttribute('class', 'select-items select-hide');
	for (j = 1; j < selElmnt.length; j++) {
		/*for each option in the original select element,
		create a new DIV that will act as an option item:*/
		optionMenu = document.createElement('DIV');
		optionMenu.innerHTML = selElmnt.options[j].innerHTML;
		optionMenu.addEventListener('click', function(e) {
      e.stopPropagation(); e.preventDefault();
			/*when an item is clicked, update the original select box,
			and the selected item:*/
			var searchAndRemove, i, iThree, index, parentSelector;
			index = this.parentNode.parentNode.getElementsByTagName("select")[0];
			parentSelector = this.parentNode.previousSibling;
			for (i = 0; i < index.length; i++) {
				if (index.options[i].innerHTML == this.innerHTML) {
					index.selectedIndex = i;
					parentSelector.innerHTML = this.innerHTML;
					searchAndRemove = this.parentNode.getElementsByClassName('same-as-selected');
					for (iThree = 0; iThree < searchAndRemove.length; iThree++) {
						searchAndRemove[iThree].removeAttribute("class");
					}
					this.setAttribute('class', 'same-as-selected');
					break;
				}
			}
      parentSelector.click();
      $('#items-cards__option').change();
		});
		newItemEl.appendChild(optionMenu);
	}
	searchEl[i].appendChild(newItemEl);
	newSelectedEl.addEventListener('click', function(e) {
    e.stopPropagation(); e.preventDefault();
		/*when the select box is clicked, close any other select boxes,
		and open/close the current select box:*/
		// e.stopPropagation();
		closeAllSelect(this);
		this.nextSibling.classList.toggle('select-hide');
		this.classList.toggle('select-arrow-active');
		this.classList.toggle('select-border-change');
	});
}

function closeAllSelect(elmnt) {
	/*a function that will close all select boxes in the document,
	except the current select box:*/
	var searchEl, searchAndRemove, i, arrNo = [];
	searchEl = document.getElementsByClassName('select-items');
	searchAndRemove = document.getElementsByClassName('select-selected');
	for (i = 0; i < searchAndRemove.length; i++) {
		if (elmnt == searchAndRemove[i]) {
			arrNo.push(i)
		} else {
			searchAndRemove[i].classList.remove('select-arrow-active');
			searchAndRemove[i].classList.remove('select-border-change');
		}
	}
	for (i = 0; i < searchEl.length; i++) {
		if (arrNo.indexOf(i)) {
			searchEl[i].classList.add('select-hide');
		}
	}
}
/*if the user clicks anywhere outside the select box, then close all select boxes:*/
document.addEventListener("click", closeAllSelect);

(function($) {
	$(window).on('load', function() {
    cardsGenerate();   
  });
})(jQuery);

$('.content-card__content').each(function() {
  if($(this).children('.content-card__img').length == 0) {
    $(this).addClass('no-img__border');
  }
});


$('.lhs__menu-toggle').on('click', function() {
  if ($('.lhs__menu-toggle').attr('data-expanded') === 'true') {
    $(this).addClass('lhs__menu-toggle-open-border');
  } else {
    $(this).removeClass('lhs__menu-toggle-open-border');
  }
});

if (hasTouch()) { // remove all :hover stylesheets
  try { // prevent exception on browsers not supporting DOM styleSheets properly
    for (var si in document.styleSheets) {
      var styleSheet = document.styleSheets[si];
      if (!styleSheet.rules) continue;

      for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
        if (!styleSheet.rules[ri].selectorText) continue;

        if (styleSheet.rules[ri].selectorText.match(':hover')) {
            styleSheet.deleteRule(ri);
        }
      }
    }
  } catch (ex) {}
}

function hasTouch() {
  return 'ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
}

$('.select-selected').on('click', function() {
  if ($('.select-selected:contains("Date Range")').length > 0) {
    $('.items-cards__select').animate({
      'padding-bottom' : '7rem'
    }, 'fast');
    $('.items-cards__dates').removeClass('select-hide');
  }
  else {
    $('.items-cards__select').animate({
      'padding-bottom' : 0
    }, 'fast');
    $('.items-cards__dates').addClass('select-hide');
  }
});

$('#filters > input').focus(function() {
  $('label[for="' + this.id + '"]').css('outline', '-webkit-focus-ring-color auto 5px');
}).blur(function() {
  $('label[for="' + this.id + '"]').css('outline', 'none');
});

$('#filters > input:checkbox').prop('checked', false);

$('.card-link').focus(function() {
  $(this).children().css('outline', '-webkit-focus-ring-color auto 5px');
}).blur(function() {
  $(this).children().css('outline', 'none');
});

$(function() {
  $('.items-cards__select-group select').bind({
      focus : function() {
          $('.select-selected').css('outline','-webkit-focus-ring-color auto 5px');
      },
      blur : function() {
        $('.select-selected').css('outline','none');
      }
  });
});

var ajaxCallNewCards = null;
$( "#filters > input, #items-cards__option" ).change(function() {
  if (ajaxCallNewCards !== null){
    ajaxCallNewCards.abort();
  }
  var url = window.location.href.split('?')[0]; 
  // if(url.indexOf("?query") > -1) {
  //   url = url.split('query')[0];
  // }
  // else {
  //   url = url + '?';
  // }
  ajaxCallNewCards = $.get(url + '?' + $('#items-cards__form').serialize(), function( data ) {
    $( ".items-cards" ).replaceWith($(data).find('.items-cards'));
  }).done(function(){
    $( ".grid" ).removeClass('hidden');
    console.log(this);
    cardsGenerate();
    ajaxCallNewCards = null;
  });
});
//when load more button clicked
$('body').on('click', '#load-more', function() {
  var url = window.location.href.split('?')[0]; 
  // if(url.indexOf("?query") > -1) {
  //   url = url.split('query')[0]; //TIS-70
  // }
  // else {
  //   url = url + '?';
  // }
  $.get(url + '?' + $('#items-cards__form').serialize() + '&start_rank=' + $('.load-more-btn').data('nextStart'), function( data ) {
    var $newCards = $(data).find('.card-link');
    $( '.items-cards > .grid' ).append($newCards).isotope( 'appended', $newCards);
    $( '#load-more' ).replaceWith($(data).find('#load-more'));
    console.log(this);
    // if ($('.load-more-btn').data('nextStart') == 'null') {
    //   $('#load-more').hide();
    // }
  });
});


function cardsGenerate() {
  if ($('.items-cards').length > 0) {
    $('.grid').removeClass('hidden');
    // init Isotope
    var $container = $('.grid').isotope({
      itemSelector: '.content-card__container',
      // getSortData: {
      //   date: function(itemElem) {
      //     //
      //     var date = Date.parse($(itemElem).find('.content-card__date').text());
      //     // console.log(date);
      //     return date;
      //   },
      //   name: function(itemElem) {
      //     var name = $(itemElem).find('.content-card__title').text();
      //     return name;
      //   }
      // },
      transitionDuration: 0,
      sortBy: 'date',
      sortAscending: true,
      masonry: {
        columnWidth: '.grid-sizer'
      }
    });
  
    // Create the load more button at the end of .grid
    // $container.after('<div id="load-more"><div class="load-more"><button class="load-more-btn">Load more</button></div></div>');
    
    //number of items loaded on init & onclick load more button
    var initShow = 9; 
    //counter for load more button
    var counter = initShow;
    // get Isotope instance
    var iso = $container.data('isotope'); 
    //value of the filter
    var filterValue = '*'; 
  
    // $('#submitDates').click(function(submit) {
    //   submit.preventDefault();
      
    //   var itemElem = $('.content-card__container');

    //   var filters = [];

    //   var dateDay = Date.parse($('#dateFrom.datepicker').val());

    //   var dateDayTwo = Date.parse($('#dateTo.datepicker').val());

    //   var date = Date.parse($(itemElem).each(function(){find('.content-card__date')}));

    //   if (date > dateDay && date < dateDayTwo) {
    //     $(itemElem).hide();
    //   }
    // });
  
    // bind sort button click
    // $('.select-selected').click(function() {
    //   // Count how many click there are
    //   var clicks = $(this).data('clicks');
    //   // Every off click get the respective values and input them into Isotope
    //   if (clicks) {
    //     // get sorting options e.g. date or name
    //     var sortByValue = $('#items-cards__option').find(":selected").val();
    //     // get ordering options e.g. A-Z, Z-A, Newest or Oldest
    //     var orderBy = $('#items-cards__option').find(":selected").data('sort');
    //     // Use the values to filter the Isotope listing
    //     $container.isotope({
    //       sortBy: sortByValue,
    //       sortAscending: orderBy
    //     });
    //     // Load the loadmore button and limit the scope to six
    //     loadMore(initShow, filterValue, true);
    //   } else {}
    //   $(this).data("clicks", !clicks);
    // });
  
    //execute function onload of social wall page
    loadMore(initShow, filterValue, true);
    
    // var loadMore = function(){}
    function loadMore(toShow, filter, inititial) {
      // Remove the hidden class from the elements within the filter range that have the class
      $container.find(filter + '.hidden').removeClass('hidden');
      // Find the items that are not within the shown content e.g. initShow top 6
      var hiddenElems = iso.filteredItems.slice(toShow, iso.filteredItems.length).map(function(item) {
        return item.element;
      })
      // Add the class to those elements
      $(hiddenElems).addClass('hidden');
      $container.isotope('layout');
      // when no more to load, hide show more button
    }
  }
  }